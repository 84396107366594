import {defineStore} from 'pinia';
import {ref, computed} from 'vue';

export const useMaintenanceStore = defineStore('maintenance', () => {
    const down = ref(false);

    function setDown() {
        down.value = true;
    }

    function setUp() {
        down.value = false;
    }

    function isDown() {
        return down.value;
    }

    return {
        setDown,
        setUp,
        isDown
    };
});
