export const gtm = {
    push(data) {
        if (process.client) {
            const config = useRuntimeConfig();
            const googleTagManager = config.public?.scripts?.googleTagManager;

            if (googleTagManager) {
                useScriptGoogleTagManager().push(data);
            } else {
                // console.log('GoogleTagManager:', JSON.stringify(data));
            }
        }
    },

    event(event, value) {
        gtm.push({
            "event": 'ga-event',
            "eventCategory": event,
            "eventAction": value
        });
    },

    banner(code, name) {
        gtm.push({
            "event": 'ga-event',
            "eventCategory": 'Banner',
            "eventAction": 'click',
            "eventLabel": name,
            "eventValue": code
        });
    }
};