import {defineStore} from 'pinia';
import {ref} from 'vue';

export const useMenuStore = defineStore('menu', () => {
    const topMain = ref([]);
    const footerMain = ref([]);
    const footerBottom = ref([]);
    const profile = ref([]);

    function initData(data) {
        topMain.value = data.top_main;
        footerMain.value = data.footer_main;
        footerBottom.value = data.footer_bottom;
        profile.value = data.profile;
    }

    return {
        topMain,
        footerMain,
        footerBottom,
        profile,
        initData,
    };
});
