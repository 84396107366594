import { ref, watch, nextTick } from 'vue';
import eventBus from '../eventBus.js';
import { getFromServer } from './getFromServer.js';

export async function useTranslatableData(url, opts = []) {
    const data = ref(null);

    async function fetchData() {
        data.value = await getFromServer(url, opts);
    }

    watch(() => eventBus.languageChanged, async (newLang) => {
        await fetchData();
        await nextTick();
    });

    await fetchData();

    return data;
}
