<template>
    <Modal ref="baseModal" :id="modalName" :show-footer="false" :show-header="false" modal-dialog-class="modal-fullscreen modal-fullscreen-top">
        <div class="container-xxl cart_add_block_wr text-center" v-if="product" @mouseenter="hoverStart" @mouseleave="hoverEnd" ref="modalRef">
            <div class="cartForm h-100" id="cartForm" onsubmit="return!1;">
                <div class="row align-content-center justify-content-center">

                    <div v-if="first_connected_product.name" class="col-12 col-lg-6 border-lg-end d-none d-lg-flex align-content-center">
                        <div class="row align-content-center">
                            <div class="col-12 col-lg-6 d-lg-flex align-items-center justify-content-center">
                                <div class="wp-400 pe-lg-5 d-flex justify-content-center add-to-cart-img add-to-cart-img-with-rec">
                                    <div v-if="product.image" v-html="product.image"></div>
                                    <span v-else>{{ $t('No product image available yet') }}</span>
<!--                                    <img :src="successBg" class="size-50" :alt="$t('Success!')"/>-->
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 pt-lg-4">
                                <h2>{{ $t('Recommended for you') }}</h2>

                                <div
                                    v-if="first_connected_product.image"
                                    class="image product-image-cart mb-2"
                                    v-html="first_connected_product.image">
                                </div>
                                <span v-else class="img-empty"><strong>{{
                                        $t('Image coming soon')
                                    }}</strong></span>
                                <p class="mb-0">{{ first_connected_product.name }}</p>
                                <h2 class="h1-sm mt-3">{{ $formatter.price(first_connected_product.netto_price) }}</h2>
                                <div class="row align-content-center justify-content-center pb-4">
                                    <div class="col-12 col-md-9">
                                        <button type="button" @click="addConnectedProductToCart()" class="btn btn-outline-primary btn-lg w-100">{{
                                                $t('Add to cart')
                                            }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="col-12 col-lg-6 border-lg-end d-none d-lg-flex align-content-center justify-content-center">
                        <div class="wp-400 pe-lg-5 d-flex align-items-center justify-content-center add-to-cart-img">
                            <div v-if="product.image" v-html="product.image"></div>
                            <span v-else class="img-empty"><strong>{{ $t('Image coming soon') }}</strong></span>
<!--                            <img :src="successBg" class="size-50" alt="Success">-->
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 d-flex align-content-center justify-content-center">
                        <div class="wp-400 ps-lg-5">
                            <div class="modal-header bg-white">
                                <button type="button" class="btn-close" @click="closeModal()" aria-label="Close"></button>
                            </div>
<!--                            <img :src="successBg" class="size-24 mb-2" alt="Success"/>-->
                            <h2 id="exampleModal1Label">{{ $t('Added to cart') }}</h2>
                            <p class="mb-0">{{ product.name }}</p>
                            <p class="mb-0">{{ $t('Quantity :qty at', {qty: order_product.quantity}) }} {{ $formatter.price(order_product.total_price) }}</p>
                            <h2 class="h1-sm mt-3">{{ $t('Total') }}&nbsp;{{
                                    $formatter.price(order.sub_total_price)
                                }}</h2>
                            <div class="row align-content-center justify-content-center pb-4 pt-2">
                                <div class="col-12 col-md-9">
                                    <a :href="useLocaleStore().url('checkout')" type="button" class="btn btn-primary btn-lg ms-0 w-100">{{
                                            $t('Begin checkout')
                                        }}</a>
                                    <button @click="closeModal()" type="button" class="btn btn-outline-primary mt-3 btn-lg w-100">{{
                                            $t('Continue Shopping')
                                        }} ({{ timer }})
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import Modal from './Modal.vue';
import {useModal} from "../../stores/ModalStore.js";
import {useCartStore} from "../../stores/CartStore.js";

const props = defineProps({
    observer: {},
    order: {
        type: Object,
        required: true
    },
    product: {
        type: Object,
        required: true
    },
    order_product: {
        type: Object,
        required: true
    },
    first_connected_product: {
        type: Object,
        required: false
    },
    dataLayer: {
        type: Object,
        required: false
    },
});

const timer = ref(5);
const interval = ref(null);
const isHovering = ref(false);
const baseModal = ref(null);

// const successBg = computed(() => {
//     return new URL('@img/i-success-bg.svg', import.meta.url).href;
// });

const modalName = 'ProductAddedToCart';

const startTimer = () => {
    interval.value = setInterval(() => {
        if (isHovering.value) return;
        if (timer.value > 1) {
            timer.value -= 1;
        } else if (timer.value === 1) {
            clearInterval(interval.value);
            closeModal();
        }
    }, 1000);
};

const hoverStart = () => {
    isHovering.value = true;
};

const hoverEnd = () => {
    isHovering.value = false;
};

const formatPrice = (price) => {
    return props.order?.currency_info?.unit_text
        ? `$${price} ${props.order.currency_info.unit_text}`
        : `$${price}`;
};

const addConnectedProductToCart = () => {
    props.first_connected_product?.id && useCartStore().storeUpdateSave(props.first_connected_product.id, 1, false);
};

const closeModal = () => {
    useModal().closeModal(modalName);
};

onMounted(() => {
    startTimer();
});

onUnmounted(() => {
    clearInterval(interval.value);
});
</script>
