import {getFromAny} from "../composables/getFromAny.js";
import {useRequestEvent} from 'nuxt/app';
import {useBreadcrumbStore} from "../stores/BreadcrumbStore.js";
import {useCatalogStore} from "../stores/CatalogStore.js";

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (to.name.startsWith('search')) {
        await useCatalogStore().fetchCatalog();
        return;
    }
    if (!to.name.startsWith('slug') && !isHomepage(to)) {
        return;
    }

    const routerStore = useRouterStore();

    const pathsAreEqual = to.path === from.path;
    const queryString = pathsAreEqual && Object.keys(to.query).length > 0 ? new URLSearchParams(to.query).toString() : '';
    const apiUrl = `router${queryString ? '?' + queryString : ''}`;

    async function fetchPageType(path, query = {}) {
        if (!routerStore.hasPage(path)) {
            let data = null;
            if (process.server && useRequestEvent().context.pageInfo) {
                data = useRequestEvent().context.pageInfo.data;

            } else {
                try {
                    data = await getFromAny(apiUrl, {
                        method: 'POST',
                        body: {path},
                        query
                    });
                } catch (e) {
                    showError({
                        statusCode: 503,
                    });
                    return abortNavigation();
                }
            }

            if (!data.url) {
                data.url = path;
            }
            if (data.name !== 'undefined') {
                routerStore.addPage(data);
            } else {
                //client navigation for legacy
                routerStore.addPage(data); //prevents loop
                if (process.client) {
                    if (to.matched[0].name.startsWith('slug___')) {
                        window.location.href = path;
                        return;
                    }
                }
                throw createError({ statusCode: 404, statusMessage: 'Page not found' })
            }
            return data;
        }
        return routerStore.getPage(path);
    }

    function setMetaData(pageType) {
        if (!pageType.meta) {
            return;
        }
        const { title, description, hreflang, og } = pageType.meta;
        const headOptions = {
            title,
            description,
            link: [
                {
                    rel: 'canonical',
                    href: hreflang.find((lang) => lang.key === 'x-default')?.href,
                },
                ...hreflang,
            ],
            meta: og,
        };
        useHead(headOptions);
    }

    function setSchema(pageType) {
        if (!pageType.schema) {
            return;
        }
        const schemaArray = JSON.parse(pageType.schema);
        schemaArray.forEach(schema => useSchemaOrg(schema));
    }

    function isHomepage(to) {
        return to.path === ('/' + useLocaleStore().getLanguage() + '/');
    }
    const pageType = await fetchPageType(to.path, to.query);

    //product/catalog
    if (pageType.url !== to.path) {
        return navigateTo(pageType.url);
    }

    //homepage
    if (isHomepage(to)) {
        useHomepageStore().setHomepageData(pageType);
    }

    setMetaData(pageType);
    setSchema(pageType);

    useBreadcrumbStore().items = [];
});
