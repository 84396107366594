import {markRaw, reactive, toRefs} from 'vue';

const state = reactive({
    modals: {}
});

function openModal(id, component, props = {}) {
    state.modals[id] = { component: markRaw(component), props };
}

function closeModal(id) {
    delete state.modals[id];
}

function modal(id) {
    if (state.modals[id]) {
        return state.modals[id].component;
    }
    return null;
}


function modalIsOpen(id) {
    return state.modals[id] !== undefined;
}

function modalProps(id) {
    return state.modals[id] ? state.modals[id].props : {};
}


export function useModal() {
    return {
        ...toRefs(state),
        openModal,
        closeModal,
        modalIsOpen,
        modal,
        modalProps
    };
}
