export default defineNuxtPlugin((nuxtApp) => {
    const formatter = {
        price(price, currency) {
            // console.log('1: ' + currency);
            // console.log('2: ' +  useLocaleStore().currency);
            currency = currency && typeof currency !== 'undefined' ? currency : useLocaleStore().currency.unit_text;
            // console.log('3: ' + currency);
            if (typeof currency === 'undefined') { currency = 'EUR'; }
            // console.log('4: ' + currency);
            // console.log(typeof currency);

            return new Intl.NumberFormat(useLocaleStore().getLanguage(), {
                style: 'currency',
                currency: currency,
                currencyDisplay: 'narrowSymbol',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            }).format(price);
        },

        percent(value) {
            return new Intl.NumberFormat(useLocaleStore().getLanguage(), {
                minimumFractionDigits: 2,
            }).format(value);
        },
    };

    return {
        provide: {
            formatter: formatter,
        },
    };
});
