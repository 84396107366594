import {defineStore} from 'pinia';
import {ref, computed} from 'vue';


export const useRouterStore = defineStore('router', () => {
    const pages = ref([]);

    async function addPage(data) {
        if (!hasPage(data.url)) {
            pages.value.push(data);
        }
    }

    function hasPage(url) {
        return pages.value.findIndex(item => item.url === url) >= 0;
    }

    function deletePage(url) {
        const index = pages.value.findIndex(item => item.url === url);
        if (index >= 0) {
            pages.value.splice(index, 1);
        }

        return this;
    }

    function getPage(url) {
        return pages.value.find(function (item) {
            let page = item.url === url;
            if (page) {
                return page;
            }

            return pages.value.find(item => url === item.url.replace(/^\/[^\/]*\//, '/'));
        });
    }

    function getCurrentPage() {
        return getPage(useRoute().path);
    }

    return {
        pages,
        addPage,
        hasPage,
        getPage,
        deletePage,
        getCurrentPage
    };
});
