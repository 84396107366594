import {useLocaleStore} from "../stores/LocaleStore.js";
import {useSpotRatesStore} from "../stores/SpotRatesStore.js";
import {useWatchlistStore} from "../stores/WatchlistStore.js";
import {useCartStore} from "../stores/CartStore.js";
import {useSettingsStore} from "../stores/SettingsStore.js";
import {useUserStore} from "../stores/UserStore.js";
import {useMenuStore} from "../stores/MenuStore.js";
import {getFromServer} from '../composables/getFromServer.js';

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (useSettingsStore().dataFetched) {
        return;
    }

    // try {
        await initData();
    // } catch (e) {
    //     console.log(e);
    //
    // }
    //

    async function initData() {
        try {
            const data = await getFromServer('init');

            useSpotRatesStore().initData(data.rates);
            useLocaleStore().initData(data.locale);
            useSettingsStore().initData(data.settings);
            useWatchlistStore().initData(data.watchlist);
            useCartStore().initData(data.cart);
            useUserStore().initData(data.user);
            useMenuStore().initData(data.menu);
        } catch (e) {
            showError({
                statusCode: 503,
            });
            return abortNavigation();
        }
    }
});
