import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { getFromServer } from "../composables/getFromServer.js";

export const useSpotRatesStore = defineStore('spot-rates', () => {
    const interval = 60;
    const spotRates = ref({});
    const timer = ref(interval);
    const timerTick = ref(interval);

    function initData(data) {
        spotRates.value = data;
    }

    async function fetchSpotRates() {
        spotRates.value = await getFromClient('spot-rates');
    }

    function updateSpotRates() {
        getFromClient('spot-rates').then((rates) => {
            Object.keys(rates).forEach(key => {
                if (!spotRates.value[key]) {
                    spotRates.value[key] = {};
                }
                updateRate(key, rates[key]);
            });
        });
    }

    function updateRate(key, newRate) {
        let oldPrice = spotRates.value[key]?.price || 0;
        let direction = getDirection(newRate.price, oldPrice);

        spotRates.value[key] = {
            ...spotRates.value[key],
            prev_price: newRate.prev_price,
            price: newRate.price,
            changeDirection: direction,
            diff: getDiff(newRate.price, newRate.prev_price),
            percent: getPercent(newRate.price, newRate.prev_price),
            icon: getIcon(newRate.price, newRate.prev_price)
        };

        //remove change arrow
        setTimeout(function(){
            spotRates.value[key].changeDirection = 'none';
        }, 3000);
    }

    function getDirection(newPrice, oldPrice) {
        if (newPrice > oldPrice) return 'up';
        if (newPrice < oldPrice) return 'down';
        return 'none';
    }

    function getIcon(currentPrice, prevPrice) {
        return getDirection(currentPrice, prevPrice);
    }

    function getPercent(currentPrice, prevPrice) {
        return prevPrice ? Math.round((currentPrice - prevPrice) / prevPrice * 10000) / 100 : 0;
    }

    function getDiff(currentPrice, prevPrice) {
        return Math.round((currentPrice - prevPrice) * 100) / 100;
    }

    function countdownTimer() {
        timerTick.value--;
        if (timerTick.value <= 0) {
            timerTick.value = timer.value;
            updateSpotRates();
        }
    }

    function startTimer() {
        timerTick.value = timer.value;
        setInterval(countdownTimer, 1000);
    }

    function getTimerLeft() {
        const pad = (n) => n < 10 ? `0${n}` : n;
        const m = Math.floor(timerTick.value / interval);
        const s = timerTick.value % interval;

        return `${pad(m)}:${pad(s)}`;
    }

    return {
        initData,
        fetchSpotRates,
        spotRates,
        startTimer,
        getTimerLeft,
        timerTick
    };
});
