import validate from "/usr/src/nuxtapp/node_modules/nuxt/dist/pages/runtime/validate.js";
import _100_45decode_45path_45global from "/usr/src/nuxtapp/middleware/100.decode-path.global.js";
import _140_45redirect_45trailing_45slash_45global from "/usr/src/nuxtapp/middleware/140.redirect-trailing-slash.global.js";
import _150_45stores_45data_45global from "/usr/src/nuxtapp/middleware/150.stores-data.global.js";
import _160_45route_45data_45global from "/usr/src/nuxtapp/middleware/160.route-data.global.js";
import manifest_45route_45rule from "/usr/src/nuxtapp/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _100_45decode_45path_45global,
  _140_45redirect_45trailing_45slash_45global,
  _150_45stores_45data_45global,
  _160_45route_45data_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}